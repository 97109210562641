import React from 'react';
import { Card, CardMedia, CardActions, CardContent, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Navbar from '../common/Navbar';

const useStyles = makeStyles(theme => ({
    root: {
	marginTop: theme.spacing(10),
	padding: theme.spacing(4),
	margin: 'auto',
	maxWidth: '70%',
    },
    media: {
	height: 150,
    },
    text: {
	textAlign: 'center',
    },
    button: {
	margin: 'auto',
    },
}));

const LandingPage = () => {
    const classes = useStyles();

    return (
	<div>
	    <Navbar />
	    <Card className={classes.root} elevation={3}>
		<CardMedia
		    className={classes.media}
		    image="https://gestion.escueladidactica.com/uploads/company/logo.png"
		    title="Logo Escuela Didáctica"
		/>
		<CardContent>
		    <Typography className={classes.text} gutterBottom variant="h5" component="h2">
			¡Bienvenido al License Key Manager de Escuela Didáctica!
		    </Typography>
		    <Typography className={classes.text} variant="body2" color="textSecondary" component="p">
			Aquí podrás gestionar las licencias de los plugins desarrollados por Escuela Didáctica, por favor 
			inicia sesión para comenzar.
		    </Typography>
		</CardContent>
		<CardActions>
		    <Button to="/login" component={Link} className={classes.button} variant="contained" color="primary">
			Iniciar sesión
		    </Button>
		</CardActions>
	    </Card>
	</div>
    );
}

export default LandingPage;
