import React, { useContext, useState, useEffect } from 'react';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, Container, Snackbar  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../common/Navbar';
import SessionContext from '../../context/Session/Context';

const useStyles = makeStyles((theme) => ({
    paper: {
	marginTop: theme.spacing(8),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
    },
    avatar: {
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
    },
    form: {
	width: '100%',
	marginTop: theme.spacing(3),
    },
    submit: {
	margin: theme.spacing(3, 0, 2),
    },
}));

const Update = () => {
    const classes = useStyles();

    const { loggedIn } = useContext(SessionContext);

    useEffect(() => {
	if (state.id) getLicence(state.id);
    }, [])

    const [state, setState] = useState({
	id: useParams().id || 0,
	wwwroot: '',
	fecha_fin: '',
	plugin: '',
	created: {
	    bool: false,
	    text: '',
	},
    });

    const createLicence = async (wwwroot, fecha_fin, plugin) => {
	const res = await axios.post('https://sia.escueladidactica.com/controller/add.php', { wwwroot, fecha_fin, plugin });
	if (res.data.key) handleChange('created', {bool: true, text: 'Licencia generada: ' + res.data.key});
    }

    const editLicence = async (id, wwwroot, fecha_fin, plugin) => {
	const res = await axios.post('https://sia.escueladidactica.com/controller/edit.php', { id, wwwroot, fecha_fin, plugin });
	if (res.data.data) handleChange('created', {bool: true, text: 'Licencia modificada'});
    }

    const getLicence = async (id) => {
	const res = await axios.post('https://sia.escueladidactica.com/controller/search_license.php', { id })
	const { wwwroot, fecha_fin, plugin } = res.data.data[0];
	setState({...state, wwwroot, fecha_fin, plugin});
    }

    const handleSubmit = e => {
	e.preventDefault();
	if (state.id) {
	    editLicence(state.id, state.wwwroot, state.fecha_fin, state.plugin);
	} else {
	    createLicence(state.wwwroot, state.fecha_fin, state.plugin);
	}
    }

    const handleChange = (key, value) => {
	setState({...state, [key]: value});
    }

    if (!loggedIn) return <Redirect to="/" />

	return (
	    <div>
		<Navbar />
		<Container component="main" maxWidth="xs">
		    <CssBaseline />
		    <div className={classes.paper}>
			<Avatar className={classes.avatar}>
			    <Add />
			</Avatar>
			<Typography component="h1" variant="h5">
			    {state.id ? 'Editar': 'Crear'} licencia
			</Typography>
			<form onSubmit={handleSubmit} className={classes.form}>
			    <Grid container spacing={2}>
				<Grid item xs={12}>
				    <TextField
					onChange={e => handleChange('wwwroot', e.target.value)}
					value={state.wwwroot}
					variant="outlined"
					required
					fullWidth
					label="Dominio Moodle"
				    />
				</Grid>
				<Grid item xs={12}>
				    <TextField
					onChange={e => handleChange('fecha_fin', e.target.value)}
					value={state.fecha_fin}
					variant="outlined"
					required
					fullWidth
					label="Fecha de vencimiento"
					type="date"
					InputLabelProps={{
					    shrink: true,
					}}
				    />
				</Grid>
				<Grid item xs={12}>
				    <TextField
					onChange={e => handleChange('plugin', e.target.value)}
					value={state.plugin}
					variant="outlined"
					required
					fullWidth
					label="Nombre del plugin"
				    />
				</Grid>
			    </Grid>
			    <Button
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
				className={classes.submit}
			    >
				Guardar
			    </Button>
			</form>
		    </div>
		</Container>
		<Snackbar open={state.created.bool} autoHideDuration={6000}>
		    <MuiAlert elevation={6} variant="filled" severity="success">
			{state.created.text}
		    </MuiAlert>
		</Snackbar>

	    </div>
	);
}

export default Update;
