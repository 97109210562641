import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Edit, Delete } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../common/Navbar';

const Home = () => {
    const [state, setState] = useState({
	licences: [],
	edit: 0,
	deleting: {
	    bool: false,
	    id: 0,
	},
    });

    const columns = [
	{ field: 'id', headerName: 'ID', width: 80 },
	{ field: 'wwwroot', headerName: 'Dominio Moodle', width: 300 },
	{ field: 'fecha_inicio', headerName: 'Fecha de inicio', width: 140 },
	{ field: 'fecha_fin', headerName: 'Fecha de vencimiento', width: 140 },
	{ field: 'plugin', headerName: 'Plugin', width: 140 },
	{ field: 'key', headerName: 'Licencia', width: 320 },
	{
	    field: "",
	    headerName: "Acciones",
	    sortable: false,
	    width: 160,
	    disableClickEventBubbling: true,
	    renderCell: (params) => {
		const deleteLicence = () => {
		    const api = params.api;
		    const fields = api
			.getAllColumns()
			.map((c) => c.field)
			.filter((c) => c !== "__check__" && !!c);
		    const thisRow = {};

		    fields.forEach((f) => {
			thisRow[f] = params.getValue(f);
		    });

		    setState({...state, deleting: {bool: true, id: thisRow.id}});
		};

		const editLicence = () => {
		    const api = params.api;
		    const fields = api
			.getAllColumns()
			.map((c) => c.field)
			.filter((c) => c !== "__check__" && !!c);
		    const thisRow = {};

		    fields.forEach((f) => {
			thisRow[f] = params.getValue(f);
		    });
		    handleChange('edit', thisRow.id);
		};

		return (
		    <div>
			<Button onClick={editLicence} variant="contained" color="primary">
			    <Edit />
			</Button>
			<Button onClick={deleteLicence} variant="contained" color="secondary" style={{marginLeft: 10}}>
			    <Delete />
			</Button>
		    </div>
		);
	    }
	},
    ];

    useEffect(() => {
	getLicences();
    }, []);

    const getLicences = async () => {
	const res = await axios.get('https://sia.escueladidactica.com/controller/get_all_licenses.php');
	setState({...state, licences: res.data.data, deleting: {bool: false, id: 0}});
    }

    const handleCancel = () => {
	setState({...state, deleting: {bool: false, id: 0}});
    };

    const handleOk = async () => {
	const res = await axios.post('https://sia.escueladidactica.com/controller/remove.php', {id: state.deleting.id});
	if (res.data.status) getLicences();
    };

    const handleChange = (key, value) => {
	setState({...state, [key]: value});
    }

    if (state.edit) return <Redirect to={'/update/' + state.edit} />

	return (
	    <div>
		<Navbar />
		<div style={{ height: 600, width: '100%' }}>
		    <DataGrid rows={state.licences} columns={columns} pageSize={50} />
		</div>
		<Dialog
		    disableBackdropClick
		    disableEscapeKeyDown
		    maxWidth="xs"
		    aria-labelledby="confirmation-dialog-title"
		    open={state.deleting.bool}
		>
		    <DialogTitle id="confirmation-dialog-title">Confirmar eliminación</DialogTitle>
		    <DialogContent>
			¿Estás seguro de que deseas eliminar esta licencia?
		    </DialogContent>
		    <DialogActions>
			<Button onClick={handleCancel} color="primary">
			    Cancelar
			</Button>
			<Button onClick={handleOk} color="primary">
			    Eliminar
			</Button>
		    </DialogActions>
		</Dialog>
	    </div>
	);
}

export default Home;
