import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LockOpen, ExitToApp, Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import SessionContext from '../../../context/Session/Context';

const useStyles = makeStyles((theme) => ({
    root: {
	flexGrow: 1,
    },
    menuButton: {
	marginRight: theme.spacing(2),
    },
    title: {
	flexGrow: 1,
	textDecoration: 'none',
    },
}));

const Navbar = () => {
    const classes = useStyles();

    const { loggedIn, logOut } = useContext(SessionContext);

    const LoginButton = () => (
	<IconButton to="/login" component={Link} color="inherit">
	    <LockOpen />
	</IconButton>
    );

    const LogoutButton = () => (
	<IconButton onClick={logOut} color="inherit">
	    <ExitToApp />
	</IconButton>
    );

    return (
	<div className={classes.root}>
	    <AppBar position="static">
		<Toolbar>
		    <Typography component={Link} to="/" variant="h6" color="inherit" className={classes.title}>
			License Key Manager
		    </Typography>
		    {loggedIn ? 
			<div>
			    <IconButton to="/update" component={Link} color="inherit">
				<Add />
			    </IconButton>
			    <LogoutButton />
			</div>
			: <LoginButton />}
		</Toolbar>
	    </AppBar>
	</div>
    );
}

export default Navbar;
