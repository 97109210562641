import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Home from './components/Home';
import Login from './components/Login';
import Update from './components/Update';
import SessionState from './context/Session/State';
import SessionContext from './context/Session/Context';

const Router = () => {
    const { loggedIn } = useContext(SessionContext);

    return (
	<BrowserRouter>
	    <Switch>
		<Route path="/" exact component={loggedIn ? Home : LandingPage} />
		<Route path="/login" component={Login} />
		<Route path="/update/:id?" component={Update} />
	    </Switch>
	</BrowserRouter>
    );
}

const App = () => {

    return (
	<SessionState>
	    <Router />
	</SessionState>
    );
}

export default App;
