import React, { useContext, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LockOutlined } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../common/Navbar';
import SessionContext from '../../context/Session/Context';

const useStyles = makeStyles((theme) => ({
    paper: {
	marginTop: theme.spacing(8),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
    },
    avatar: {
	margin: theme.spacing(1),
	backgroundColor: theme.palette.secondary.main,
    },
    form: {
	width: '100%',
	marginTop: theme.spacing(1),
    },
    submit: {
	margin: theme.spacing(3, 0, 2),
    },
}));

const Login = () => {
    const classes = useStyles();
    
    const [state, setState] = useState({
	email: '',
	password: '',
    });
    
    const { loggedIn, logIn } = useContext(SessionContext);
    
    const handleSubmit = e => {
	e.preventDefault();
	handleLogin(state.email, state.password);
    }

    const handleChange = (key, value) => {
	setState({...state, [key]: value});
    }

    const handleLogin = async (email, password) => {
	const res = await axios.post('https://sia.escueladidactica.com/controller/login.php', { email, password });
	if (res.data.data) logIn();
    }

    if (loggedIn) return <Redirect to="/" />

    return (
	<div>
	    <Navbar />
	    <Container component="main" maxWidth="xs">
		<CssBaseline />
		<div className={classes.paper}>
		    <Avatar className={classes.avatar}>
			<LockOutlined />
		    </Avatar>
		    <Typography component="h1" variant="h5">
			Inicio de sesión
		    </Typography>
		    <form onSubmit={handleSubmit} className={classes.form}>
			<TextField
			    onChange={e => handleChange('email', e.target.value)}
			    value={state.email}
			    variant="outlined"
			    margin="normal"
			    required
			    fullWidth
			    id="email"
			    label="Correo Electrónico"
			    name="email"
			    autoComplete="email"
			    autoFocus
			/>
			<TextField
			    onChange={e => handleChange('password', e.target.value)}
			    value={state.password}
			    variant="outlined"
			    margin="normal"
			    required
			    fullWidth
			    name="password"
			    label="Contraseña"
			    type="password"
			    id="password"
			    autoComplete="current-password"
			/>
			<Button
			    type="submit"
			    fullWidth
			    variant="contained"
			    color="primary"
			    className={classes.submit}
			>
			    Iniciar sesión
			</Button>
		    </form>
		</div>
	    </Container>
	</div>
    );
}

export default Login;
