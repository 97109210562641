import { LOG_IN, LOG_OUT } from '../types';

export default (state, action) => {
    const { payload, type } = action;

    switch (type) {
	case LOG_IN: 
	    return {
		...state,
		loggedIn: payload,
	    };
	case LOG_OUT:
	    return {
		...state,
		loggedIn: payload,
	    };
	default:
	    return state;
    }
}
