import React, { useReducer } from 'react';
import SessionReducer from './Reducer';
import SessionContext from './Context';

const SessionState = (props) => {
    const initialState = {
	loggedIn: false,
    };

    const [state, dispatch] = useReducer(SessionReducer, initialState);

    const logIn = () => {
	dispatch({
	    type: 'LOG_IN',
	    payload: true,
	});
    }

    const logOut = () => {
	dispatch({
	    type: 'LOG_OUT',
	    payload: false,
	})
    }

    return (
	<SessionContext.Provider value={{
	    loggedIn: state.loggedIn,
	    logIn,
	    logOut
	}}>
	    {props.children}
	</SessionContext.Provider>
    );
}

export default SessionState;
